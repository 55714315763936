<template>
  <v-row :no-gutters="$vuetify.breakpoint.xsOnly" align="start" class="my-2">
    <v-col cols="12" md="4" order="1" order-md="2" sm="6">
      <validation-provider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.pages.site.form.fields.paciNumber')"
                           rules="required">
        <v-text-field v-model="siteData.paci_license_number" :error-messages="errors"
                      :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.paciNumber')" dense outlined/>
      </validation-provider>
    </v-col>
    <v-col cols="12" md="4" order="2" order-md="3" sm="6">
      <kurcc-date-picker :date.sync="siteData.paci_license_expire_date" :grow="$vuetify.breakpoint.lgAndUp"
                         :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.licenseExpireDate')"
                         dense name="PACI License Expire Date"/>
    </v-col>
    <v-col cols="12" md="4" order="3" order-md="1" sm="6">
      <kurcc-image-input :image.sync="siteData.paci_license_photo"
                         :name="$vuetify.lang.t('$vuetify.pages.site.form.fields.licensePhoto')" dense/>
    </v-col>
    <v-col v-if="imageSelected" cols="12" md="4" order="4" order-md="4" sm="6">
      <kurcc-image-preview :src="siteData.paci_license_photo" alt="paci license"/>
    </v-col>
  </v-row>
</template>
<script>
import Site from '@/modules/app/models/site'

export default {
  name: 'KurccSiteStepperStep2Content',
  components: {
    KurccImageInput: () => import('@/modules/app/components/KurccImageInput'),
    KurccImagePreview: () => import('@/modules/app/components/KurccImagePreview'),
    KurccDatePicker: () => import('@/modules/app/components/KurccDatePicker')
  },
  props: {
    site: {
      type: Site,
      required: true
    }
  },
  computed: {
    siteData: {
      get () {
        return this.site
      },
      set (v) {
        this.$emit('update:site', v)
      }
    },
    imageSelected () {
      return this.siteData.paci_license_photo
    }
  }
}
</script>
